html {
  background-color: #34568b;
}

.projectDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Arial", sans-serif;
  color: white;
  background-color: #34568b;
}

.card {
  width: 90%;
  max-width: 400px;
  height: auto;
  margin: 10px;
  background-color: #3e497a;
  color: #f3dbdb;
}
