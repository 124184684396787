.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  background-color: #34568b;
  text-align: center;
}

.img {
  padding-top: 20px;
  width: 15%;
  height: 15%;
  border-radius: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #34568b;
  color: #f0f0f0;
}

.about {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #34568b;
  color: #f0f0f0;
  margin: 30px;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 60px;
  color: #f7cac9;
  height: auto;
}

.about .prompt {
  width: auto;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
}

.prompt span {
  font-size: 20px;
  margin: 25px;
  color: white;
  justify-content: center;
  align-items: center;
}

.skills {
  font-size: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h2 {
  color: #f7cac9;
  border-bottom: 2px solid black;
}

@media (max-width: 768px) {
  .home {
    color: #f3dbdb;
    padding: 20px;
  }

  .img {
    width: 50%;
    max-width: 200px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 20px;
  }

  .about {
    width: 90%;
    max-width: 400px;
    margin: 20px 0;
    padding: 10px;
  }

  .about h2 {
    font-size: 40px;
  }

  .about .prompt {
    font-size: 20px;
    margin: 15px;
  }

  .prompt span {
    font-size: 16px;
    margin: 15px;
  }

  .skills {
    font-size: 20px;
    width: 90%;
    max-width: 400px;
    margin: 20px 0;
    padding: 10px;
  }

  .skills h2 {
    font-size: 30px;
  }
}
